@tailwind base;
@tailwind components;
@tailwind utilities;

.protected-content {
	/* Prevent text selection */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */

	/* screenshot overlay */
	/* position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: rgba(255, 255, 255, 0.1); 
	z-index: 9999; */
}

@media print {
	.protected-content {
		display: none;
	}
}

/* end */

/* rainbow text styles */

.rainbow-text {
	background-image: linear-gradient(
		90deg,
		red,
		orange,
		yellow,
		green,
		blue,
		indigo,
		violet
	);
	background-size: 200%;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	animation: rainbow 5s linear infinite;
}

@keyframes rainbow {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 200%;
	}
}

.group:hover .group-hover\:opacity-100 {
	opacity: 1;
}

.group-hover\:opacity-100 {
	opacity: 0;
	transition: opacity 0.3s ease;
}
/* end   */

/* for react-markdown */
.markdown > * {
	all: revert;
}

/* end */

/* styles/theme-toggle.css */

:root {
	--background-color: #ffffff;
	--text-color: #000000;
}

.dark {
	--background-color: #1a202c;
	--text-color: #cbd5e0;
}

body {
	background-color: var(--background-color);
	color: var(--text-color);
	transition:
		background-color 0.3s,
		color 0.3s;
}

/* Additional styles for the theme toggle button */
.theme-toggle {
	display: flex;
	gap: 10px;
	margin: 1rem;
}

.theme-toggle label {
	cursor: pointer;
}

.theme-toggle input[type="radio"] {
	margin-right: 0.5rem;
}

@layer components {
	.btn-primary-zzz {
		@apply rounded-full bg-violet-500 px-5 py-2 font-semibold text-white shadow-md hover:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
	}
}
